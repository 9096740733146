<template>
    <div id="resident-next-orders">

        <DeleteProductPopin
            v-model="deleteProductPopinVisible"
            :product="productToDelete"
            @cancel="onDeleteProductPopinCancel"
            @validate="onDeleteProductPopinValidate"
        />
        <ConfirmExitPopin
            v-model="confirmExitPopinVisible"
            @no="callbacks.no"
            @yes="callbacks.yes"
        />

        <ResidentHeader page-title="Abonnements" />

        <HSecondaryNav
            :menuItems="internalMenuItems"
            class="secondary-nav"
        />

        <div class="header mb-4">
            <div class="header-content">
                <div class="title">Prochaine commande - livraison en {{ nextMonth }}</div>
                <br/>
                <div>
                    Vous trouverez ci-dessous la commande des produits et
                    services livrés/réalisés auprès du résident le mois
                    prochain, basée sur
                    <router-link
                        :to="{ name: $Routes.ResidentSubscriptionsCurrent }"
                    >l’abonnement que vous avez configuré.</router-link>
                    <br/>
                    Cette commande peut être modifiée jusqu’au <b>{{ orderCloseDate }}</b>.
                </div>
            </div>
            <GrandTotal
                :total="subscriptionAmount"
                class="grand-total"
            />
        </div>

        <div class="shops">
            <div
                class="shop"
                v-for="(shop, shopIndex) in shops"
                :key="shopIndex"
            >
                <div class="shop-header">
                    <div class="header-title">
                        {{ shop.shippingAmountLabel ? 'Vendeur' : 'Prestataire' }} : {{ shop.shopName }}
                    </div>
                    <div
                        v-if="shop.shippingAmountLabel"
                        class="shipping-legend"
                    >Coût de la livraison : {{ shop.shippingAmountLabel }}</div>
                </div>
                <div class="shop-products">
                    <SubscriptionCard
                        v-for="(product, productIndex) in shop.products"
                        :key="productIndex"
                        class="product"
                        :product="product"
                        @update-frequency="onUpdateFrequency"
                        @remove-quantity="onRemoveQuantity"
                        @add-quantity="onAddQuantity"
                        @delete-quantity="onDeleteQuantity"
                        :frequencyVisible="false"
                        :quantityLimited="product.quantity > product.quoteQuantity"
                        :addQuantityDisabled="product.type == 'service'"
                    >
                        <template v-slot:footer-view>
                            <HBtn
                                style-type="tertiary"
                                icon="TrashIcon"
                                class="delete-direct-btn"
                                data-cy="delete-direct-btn"
                                :disabled="isEditionForbidden"
                                @click="onProductDeleteBtnClick(product)"
                            >Retirer de la commande</HBtn>
                            <HBtn
                                style-type="tertiary"
                                icon="PencilIcon"
                                class="edit-btn"
                                data-cy="edit-btn"
                                :disabled="isEditionForbidden"
                                @click="onProductUpdateBtnClick(product)"
                            >Modifier</HBtn>
                        </template>
                        <template v-slot:footer-edit>
                            <HBtn
                                style-type="tertiary"
                                class="cancel-btn"
                                data-cy="cancel-btn"
                                @click="onProductCancelBtnClick(product)"
                            >Annuler</HBtn>
                            <HBtn
                                class="save-btn"
                                data-cy="save-btn"
                                @click="onProductSaveBtnClick(product)"
                            >Enregistrer</HBtn>
                        </template>
                    </SubscriptionCard>
                </div>
            </div>

            <div class="shop" v-if="missingProducts.length > 0">
                <div class="shop-header">
                    <div class="header-title">Produits indisponibles</div>
                </div>
                <div class="shop-products">
                    <SubscriptionCard
                        v-for="(product, productIndex) in missingProducts"
                        :key="productIndex"
                        class="product"
                        :product="product"
                        :frequencyVisible="false"
                        :quantityVisible="false"
                        :amountVisible="false"
                        :footerVisible="false"
                    ></SubscriptionCard>
                </div>
            </div>

            <div class="shop" v-if="missingServices.length > 0">
                <div class="shop-header">
                    <div class="header-title">Services indisponibles</div>
                </div>
                <div class="shop-products">
                    <SubscriptionCard
                        v-for="(product, productIndex) in missingServices"
                        :key="productIndex"
                        class="product"
                        :product="product"
                        :frequencyVisible="false"
                        :quantityVisible="false"
                        :amountVisible="false"
                        :footerVisible="false"
                    ></SubscriptionCard>
                </div>
            </div>
        </div>

        <div
            v-if="isReferant && !isPaymentValid"
            class="shops-warning"
        >
            <div class="warning-header">
                <HIcon class="header-icon" icon-name="AlertCircleIcon" size="24" />
                <div class="header-text">Pas de commande prévue pour l’instant</div>
            </div>
            <div class="warning-text">
                Votre prochaine commande sera programmée
                lorsque vous aurez
                <router-link
                    class="warning-link"
                    :to="{name: $Routes.ResidentPayment}"
                >renseigné le moyen de paiement.</router-link>
            </div>
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import moment from 'moment'

import Formats from '@/constants/formats'
import Routes from '@/constants/routes'

import {
  getDescriptionAsHtml,
  getProductOptionsAsHtml,
  getServiceOptionsAsHtml
} from '@/modules/services'
import {
  getNextMonth
} from '@/modules/date'
import Oauth from '@/oauth'

import {
  HSecondaryNav,
  HBtn,
  HIcon
} from '@happytal/bo-ui-library'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import GrandTotal from '@/components/GrandTotal'
import SubscriptionCard from '@/views/residents/subscriptions/components/SubscriptionCard'
import DeleteProductPopin from '@/views/residents/subscriptions/components/DeleteProductPopin'
import ConfirmExitPopin from '@/views/residents/subscriptions/components/ConfirmExitPopin'

export default {
  props: [
    'resident_id'
  ],
  components: {
    ResidentHeader,
    HSecondaryNav,
    HBtn,
    HIcon,
    GrandTotal,
    SubscriptionCard,
    DeleteProductPopin,
    ConfirmExitPopin
  },
  data () {
    return {
      internalMenuItems: [
        {
          title: 'Récapitulatif',
          icon: 'ClipboardListIcon',
          exactPath: true,
          route: {
            name: Routes.ResidentSubscriptionsCurrent
          }
        },
        {
          title: `Prochaine commande - livrée en ${getNextMonth()}`,
          icon: 'PackageIcon',
          route: {
            name: Routes.ResidentSubscriptionsNext
          }
        }
      ],
      loaded: false,
      shops: [],
      missingProducts: [],
      missingServices: [],
      deleteProductPopinVisible: false,
      confirmExitPopinVisible: false,
      callbacks: {
        no: () => {},
        yes: () => {}
      },
      editing: false,
      productSaved: null,
      productToDelete: null
    }
  },
  computed: {
    orderCloseDate () {
      return moment().endOf('month').format(Formats.DisplayDate)
    },
    nextMonth () {
      return getNextMonth()
    },
    isEditionForbidden () {
      return this.$aclChecker.some(this.$acl, ['isEhpadManager', 'isEhpadEmployee'])
    },
    isReferant () {
      return this.$aclChecker.some(this.$acl, ['isTutor', 'isFamily', 'isHappytalSupport'])
    },
    isPaymentValid () {
      const resident = this.getCurrentResident()
      return _.get(resident, 'stripePaymentMethod', null)
    },
    subscriptionAmount () {
      return _.sumBy(this.shops, (shop) => {
        return _.sumBy(shop.products, (product) => {
          return product.price * product.quantity
        }) + (shop.shippingAmount ? shop.shippingAmount : 0)
      }).toFixed(2)
    }
  },
  methods: {
    getUserId() {
      return this.$store.getters['dataUser/getUserId']()
    },
    getInstitutionId() {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    getProductShopIndex(product) {
      return _.findIndex(this.shops, (shop) => {
        return _.find(shop.products, (shopProduct) => {
          return shopProduct.id == product.id
        })
      })
    },
    updateProduct (product) {
      const shopIndex = this.getProductShopIndex(product)
      if (shopIndex < 0) {
        return
      }
      const productIndex = _.findIndex(this.shops[shopIndex].products, (p) => {
        return p.id == product.id
      })
      this.$set(this.shops[shopIndex].products, productIndex, product)
    },
    onUpdateFrequency (product) {

    },
    onRemoveQuantity (product) {
      product.quantity--
      this.updateProduct(product)
    },
    onAddQuantity (product) {
      product.quantity++
      this.updateProduct(product)
    },
    onDeleteQuantity (product) {
      this.productToDelete = product
      this.deleteProductPopinVisible = true
    },
    onDeleteProductPopinCancel () {

    },
    onDeleteProductPopinValidate () {
      this.editing = false
      this.deleteProduct(this.productToDelete)
    },
    deleteProduct (product) {
      const basketId = this.getCurrentBasketId()
        this.$store.commit('dataBaskets/DELETE_PRODUCT', {
        basketId,
        productId: product.id
      })
      this.updateCurrentBasket()
    },
    onProductDeleteBtnClick (product) {
      this.productToDelete = product
      this.deleteProductPopinVisible = true
    },
    onProductUpdateBtnClick (product) {
      if (this.editing) {
          this.updateProduct(this.productSaved)
      }
      else {
          this.editing = true
      }
      this.productSaved = product
      this.updateProduct({
        ...product,
        mode: 'edit'
      })
    },
    onProductCancelBtnClick (product) {
      this.editing = false
      this.updateProduct(this.productSaved)
      this.productSaved = null
    },
    onProductSaveBtnClick (product) {
        this.editing = false
        this.productSaved = null
        const basketId = this.getCurrentBasketId()
        this.$store.commit('dataBaskets/UPDATE_PRODUCT_QUANTITY', {
          basketId,
          productId: product.id,
          quantity: product.quantity
        })
        this.updateCurrentBasket()
    },
    updateCurrentBasket () {
        let basket = this.getCurrentBasket()
        if (!basket) {
          return
        }
        this.$store.dispatch('dataBaskets/updateBasket', {
            basket
        })
        .then((basketUpdated) => {
            return this.$store.dispatch('dataBaskets/getBasket', {
              basketId: basket.id
            })
        })
        .then((apiBasket) => {
            basket = apiBasket
            this.updateShops()
            return this.$store.dispatch('dataBaskets/ackBasket', {
              residentId: this.resident_id,
              basketId: basket.id,
              quoteId: basket.quoteId
            })
        })
        .then((result) => {
            this.showInfoToast(`La commande a bien été modifiée.`)
        })
        .catch((error) => {
            this.showErrorToast(`Impossible de sauvegarder (${error}).`)
        })
    },
    getResidentPcp(residentId) {
      return this.$store.getters['dataPcps/getResidentPcp'](residentId)
    },
    getCurrentPcp() {
      return this.getResidentPcp(this.resident_id)
    },
    getBasketById(basketId) {
      return this.$store.getters['dataBaskets/getBasketById'](basketId)
    },
    getCurrentBasketId() {
      const resident = this.getResidentById(this.resident_id)
      return _.get(resident, 'currentBasket.basketId')
    },
    getResidentBasket(residentId) {
      const basketId = this.getCurrentBasketId()
      return this.getBasketById(basketId)
    },
    getCurrentBasket() {
      return this.getResidentBasket(this.resident_id)
    },
    getCurrentResident() {
      return this.getResidentById(this.resident_id)
    },
    getResidentById(residentId) {
      return this.$store.getters['dataResidents/getResidentById'](residentId)
    },
    updateShops() {
      this.shops = []
      const basket = this.getCurrentBasket()
      if (!basket) {
        return
      }
      const productOrders = _.get(basket, 'quoteContent.quoteContentProduct.orders', [])
      basket.basketLineProducts.forEach((basketProduct) => {
        const shopId = _.get(basketProduct, 'meta.offer.shopId', 0)
        if (!shopId) {
          return
        }
        const shopName = _.get(basketProduct, 'meta.offer.shopName', '')
        const shopOrders = productOrders.filter(order => order.shopId === shopId)
        const shippingAmount = _.sumBy(shopOrders, order => _.sumBy(order.offers, offer => offer.lineShippingPrice) || 0) || 0
        const shippingAmountLabel = shippingAmount <= 0 ? 'Offerte' : shippingAmount.toFixed(2) + ' €'
        this.shops.push({
          shopId,
          shopName,
          amount: 0,
          type: 'product',
          shippingAmount,
          shippingAmountLabel,
          products: []
        })
      })
      basket.basketLineServices.forEach((basketService) => {
        const shopId = _.get(basketService, 'meta.service.shop.id', 0)
        const shopName = _.get(basketService, 'meta.service.shop.name', '')
        this.shops.push({
          shopId,
          shopName,
          amount: 0,
          type: 'service',
          products: []
        })
      })
      this.shops = _.uniqBy(this.shops, (shop) => {
        return shop.shopId
      })
      basket.basketLineProducts.forEach((basketProduct) => {
        const shopId = _.get(basketProduct, 'meta.offer.shopId', 0)
        if (!shopId) {
          this.missingProducts.push({
            id: basketProduct.id,
            title: basketProduct.label,
            imageUrl: basketProduct.pictureUrl,
            quantity: basketProduct.quantity,
            price: basketProduct.unitPrice,
            mode: 'view'
          })
          return
        }
        const shopIndex = _.findIndex(this.shops, (shop) => {
          return shop.shopId == shopId
        })
        let quote = null
        for (const quoteShippingGroup of productOrders) {
          const code = _.get(quoteShippingGroup, 'selectedShippingType.code', '')
          if (code === basketProduct.shippingTypeCode) {
            for (const quoteOffer of quoteShippingGroup.offers) {
              if (quoteOffer.offerId === basketProduct.offerId) {
                quote = quoteOffer
              }
            }
          }
        }
        let price = null
        let quantity = null
        if (!quote) {
          console.error(`No quote found for product`, basketProduct)
          price = NaN
          quantity = NaN
        }
        else {
          price = quote.offerPrice
          quantity = quote.offerQuantity
        }
        const offer = _.get(basketProduct, 'meta', null)
        const options = offer ? getProductOptionsAsHtml({
            ...offer,
            variantInfo: {
                data: basket.variantData
            }
        }) : null
        const shortDescription = _.get(basketProduct, 'meta.product.data.shortDescription', '')
        const description = getDescriptionAsHtml(shortDescription, options)
        this.shops[shopIndex].products.push({
          id: basketProduct.id,
          imageUrl: _.get(basketProduct, 'meta.product.data.mainImage.source', ''),
          title: _.get(basketProduct, 'meta.product.data.title', ''),
          description,
          legend: `${price}€`,
          quantity: basketProduct.quantity,
          quoteQuantity: quantity,
          shopName: this.shops[shopIndex].shopName,
          price,
          type: 'product',
          mode: 'view'
        })
      })
      basket.basketLineServices.forEach((basketService) => {
        const shopId = _.get(basketService, 'meta.service.shop.id', 0)
        if (!shopId) {
          this.missingServices.push({
            id: basketService.id,
            title: basketService.label,
            imageUrl: basketService.pictureUrl,
            quantity: basketService.quantity,
            price: basketService.unitPrice,
            mode: 'view'
          })
          return
        }
        const shopIndex = _.findIndex(this.shops, (shop) => {
          return shop.shopId == shopId
        })
        const serviceOrders = _.get(basket, 'quoteContent.quoteContentService.orders', [])
        const quotes = serviceOrders.filter(order => {
          const code = _.get(order, 'service.code', '')
          return code === basketService.serviceCode
        })
        let price = null
        let quantity = null
        if (!quotes.length) {
          console.error(`No quote found for service`, basketService)
          price = NaN
          quantity = NaN
        }
        else {
          // All service quotes should have the same price
          price = quotes[0].price.totalAmount
          quantity = quotes.length
        }
        basketService.meta.options = basketService.options
        const options = getServiceOptionsAsHtml(basketService)
        const shortDescription = _.get(basketService, 'meta.service.description', '')
        const description = getDescriptionAsHtml(shortDescription, options)
        this.shops[shopIndex].products.push({
          id: basketService.id,
          imageUrl: _.get(basketService, 'meta.serviceModel.publicData.imageUrl', ''),
          title: _.get(basketService, 'meta.serviceModel.title', ''),
          description,
          legend: `${price}€`,
          quantity: basketService.quantity,
          quoteQuantity: quantity,
          shopName: this.shops[shopIndex].shopName,
          price,
          type: 'service',
          mode: 'view'
        })
      })
      this.shops = _.map(this.shops, (shop) => {
        let amount = _.sumBy(shop.products, (item) => {
          return item.price * item.quantity
        }) || 0
        amount += shop.shippingAmount || 0
        shop.amount = amount.toFixed(2)
        return shop
      })
    },
    showInfoToast(text) {
      this.$toasted.show(text, {
        theme: 'hx-toasted',
        position: 'hx-bottom-left',
        duration: 5000,
        action: [
          {
            text: 'Fermer',
            onClick: (e, to) => {
              to.goAway(0)
            }
          }
        ]
      })
    },
    showErrorToast(text) {
      this.$toasted.show(text, {
        theme: 'hx-toasted',
        position: 'hx-bottom-left',
        duration: null,
        action: [
          {
            text: 'Fermer',
            onClick: (e, to) => {
              to.goAway(0)
            }
          }
        ]
      })
    }
  },
  async mounted() {
    this.loaded = false
    let basketId = this.getCurrentBasketId()

    async.series([
          (callback) => {
            if (!this.$store.state.dataResidents.isRegistered || !basketId) {
              this.$store.dispatch('dataResidents/getResidents', {
                userId: this.getUserId(),
                institutionId: this.getInstitutionId()
              })
              .then(() => {
                callback(null)
              })
              return
            }
            callback(null)
          },
          (callback) => {
            basketId = this.getCurrentBasketId()
            if (basketId) {
              this.$store.dispatch('dataBaskets/getBasket', {
                basketId
              })
              .then(() => {
                callback(null)
              })
              return
            }
            callback(null)
          }
        ],
        (err, results) => {
          Oauth.checkUserRight(this.getResidentById(this.resident_id), this.$router)
          this.updateShops()
          this.loaded = true
        }
    )
  },
  beforeRouteLeave(to, from, next) {
    if (!this.editing) {
      next()
      return
    }
    this.callbacks.no = () => {
      next(false)
    }
    this.callbacks.yes = () => {
      this.$store.dispatch('dataPcps/getResidentPcp', {
        residentId: this.resident_id
      })
      .then((pcpApi) => {
        next()
      })
    }
    this.confirmExitPopinVisible = true
  }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

#resident-next-orders {

    .vs-alert--text {
        padding: 8px 10px;
        font-size: .9rem;
    }

    .secondary-nav {
        margin: 10px 0px 0px 0px;
    }

    .header {
        display: flex;
        align-items: center;
        margin: 20px 0px 0px 0px;
        padding: 12px 15px 12px 15px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
        border-radius: 8px;

        .header-content {
            flex-grow: 1;

            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #2C2C2C;
            }
            .legend {
                margin: 4px 0px 0px 0px;
                font-style: italic;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: #2C2C2C;
            }
        }
        .grand-total {
            flex-shrink: 0;
        }
        .header-date {
            padding: 4px 15px 4px 15px;
            background: #BDBDBD;
            border-radius: 3px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 17px;
            color: #FFFFFF;
        }
    }

    .shops {
        margin: 20px 0px 0px 0px;

        .shop {

            .shop-header {

                .header-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    color: #000000;
                }
                .shipping-legend {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                }
            }
            .shop-products {
                display: grid;
                grid-template-columns: repeat(auto-fill, 48%);
                grid-auto-rows: auto;
                grid-gap: 22px;
                row-gap: 22px;
                margin: 20px 0px 20px 0px;

                @media (max-width: map-get($breakpoints, sm)) {
                    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
                }

                .product {

                    .delete-direct-btn {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        color: #606060;
                    }
                    .edit-btn {
                        margin: 0px 0px 0px 10px;
                    }
                    .cancel-btn {

                    }
                    .save-btn {
                        margin: 0px 0px 0px 10px;
                    }
                }
            }
        }
    }

    .shops-warning {
        margin: 25px 0px 0px 0px;
        padding: 0px 0px 20px 0px;
        border-bottom: 1px solid #F2994A;

        .warning-header {
            display: flex;
            align-items: center;

            .header-icon {
                color: #F2994A;
            }
            .header-text {
                margin: 0px 0px 0px 5px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                color: #F2994A;
            }
        }
        .warning-text {
            margin: 15px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #000000;

            .warning-link {
                font-weight: 600;
            }
        }
    }
}
</style>
